import { default as _91slug_935ubP7vEdlKMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93Ic7dgMB6ANMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93Ex3584Wgv4Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93uE4KHj6FugMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/payment/[id].vue?macro=true";
import { default as _91id_93XitAM1NYCDMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/omdome/[id].vue?macro=true";
import { default as _91tab_931XUZOZkaz8Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_93rPNfgYyxXDMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_93yTonRuKz6oMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93UEhckBqVi9Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/about/[tab].vue?macro=true";
import { default as _91tab_93GOhwL5PYQrMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotel/[id]/[tab].vue?macro=true";
import { default as _91id_93aiyPrbOA9HMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/fn/[id].vue?macro=true";
import { default as _91slug_930wldfA9VRKMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/competition/[slug].vue?macro=true";
import { default as _91name_93CuTcKYouMAMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/charterpackagescampaign/[name].vue?macro=true";
import { default as _91slug_93hDmLT1UHdMMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/category/[slug].vue?macro=true";
import { default as _91ocr_93di6DuanKzkMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93i7zph6GfJxMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93Rx5wpPFq99Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93k0s5nb9PC3Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_936xINvyR6oCMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/[slug].vue?macro=true";
import { default as _91tab_935YdxdKsrLSMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as _91tab_9397iD5eLHN8Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/___sv_destination/[slug]/[tab].vue?macro=true";
import { default as activitiesbgFfWs0lBqMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/activities.vue?macro=true";
import { default as callbackMvGTLaT4y2Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/callback.vue?macro=true";
import { default as favouritescD1IXJhlGEMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/favourites.vue?macro=true";
import { default as my_45bookingsIExm7HEsWiMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/my-bookings.vue?macro=true";
import { default as blackweekexKR1DlGUbMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/blackweek.vue?macro=true";
import { default as _91slug_93YhNbe5KUnVMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/blog/[slug].vue?macro=true";
import { default as indexHkoiGqA1mkMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/blog/index.vue?macro=true";
import { default as byebyeieK7wgF5F50kMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/byebyeie.vue?macro=true";
import { default as index78sOg8UXbfMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/calendar/index.vue?macro=true";
import { default as lastminuteKwcIZA0au0Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93UqzOss2jwBMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/campaign/[name].vue?macro=true";
import { default as indexwO98wMHm55Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/campaign/index.vue?macro=true";
import { default as cataloguePLi0d49XHvMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/catalogue.vue?macro=true";
import { default as charterlastminuteelT4YePyYhMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/charterlastminute.vue?macro=true";
import { default as gruppresorz9tvbFqxRFMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/charterpackagescampaign/gruppresor.vue?macro=true";
import { default as chartersearchmRsQ4jNgf0Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/chartersearch.vue?macro=true";
import { default as complaintjxMF9XEJ9cMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/complaint.vue?macro=true";
import { default as indexX4uBWOIiAXMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/destination/index.vue?macro=true";
import { default as dinbokning_45aktuellEd6xqC6ZrRMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93ISuVK0zfhyMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/event/[slug].vue?macro=true";
import { default as index1PSVWlu3icMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/event/index.vue?macro=true";
import { default as faqRPA6gXwTg9Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/faq.vue?macro=true";
import { default as index5ZYZEhh4xRMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/favourites/index.vue?macro=true";
import { default as _91id_93nhVefnbzQKMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/giftcard/[id].vue?macro=true";
import { default as index3AtRamMYe0Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/giftcard/index.vue?macro=true";
import { default as backupJWZ2jtkD9qMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotel/backup.vue?macro=true";
import { default as indexswsczWynypMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotel/index.vue?macro=true";
import { default as _91name_93sCXxhBlfz7Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotels/[name].vue?macro=true";
import { default as indexKdRATOixiNMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotels/index.vue?macro=true";
import { default as indexz4tL6t45IXMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/index.vue?macro=true";
import { default as _91slug_9374Ci4EcSvZMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/[slug].vue?macro=true";
import { default as grouptravelKZygRSKq9PMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/grouptravel.vue?macro=true";
import { default as indexTQ4kZj68oNMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/index.vue?macro=true";
import { default as lastminuteU4DPTpqK00Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/lastminute.vue?macro=true";
import { default as map3c5zIrKIaEMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/map.vue?macro=true";
import { default as mobileAppJBovOaw1T5Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/mobileApp.vue?macro=true";
import { default as dinbokningFPpjAIegqdMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterOrYDLjNf83Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/newsletter.vue?macro=true";
import { default as offlinegZRFKMKwSuMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/offline.vue?macro=true";
import { default as packagessearchsjsqj0UpQuMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/packagessearch.vue?macro=true";
import { default as failedfFGf8IretgMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/payment/failed.vue?macro=true";
import { default as indexnglvnjHKCGMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/review/index.vue?macro=true";
import { default as searchoOneYHKijGMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/search.vue?macro=true";
import { default as indexqVjjOqUqqQMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/tourleader/index.vue?macro=true";
import { default as verification_45donek1vIG4WXF3Meta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/verification-done.vue?macro=true";
import { default as component_45stubzNoTfZ1OzlMeta } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzNoTfZ1Ozl } from "/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "tourleader-id",
    path: "/reiseleder/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/tourleader/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "titinerary",
    path: "/dagsprogram",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/titinerary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "shipinfo",
    path: "/cruiseskip",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/shipinfo/[name].vue").then(m => m.default || m)
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/omdome/[id].vue").then(m => m.default || m)
  },
  {
    name: "mytrip",
    path: "/dinreise",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/mytrip/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-lectures",
    path: "/info/forelesninger",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/lectures/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-connections",
    path: "/info/anslutning",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/connections/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/about/[tab].vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotell/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotel/[id]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/fn/[id].vue").then(m => m.default || m)
  },
  {
    name: "competition",
    path: "/konkurranse",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/competition/[slug].vue").then(m => m.default || m)
  },
  {
    name: "charterpackagescampaign",
    path: "/charterpackagescampaign",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/charterpackagescampaign/[name].vue").then(m => m.default || m)
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/bookingfaq/[ocr].vue").then(m => m.default || m)
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/booking-finish/[ocr].vue").then(m => m.default || m)
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/booking-confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "book-tripid",
    path: "/bestill/:tripid()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/book/[tripid]/[step].vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/[triptype]/[slug]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "no-destination-slug",
    path: "/destinasjon/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/___sv_destination/[slug]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "no-destination-slug-tab",
    path: "/destinasjon/:slug()/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/___sv_destination/[slug]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_935YdxdKsrLSMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/[triptype]/[slug]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "activities",
    path: "/activities",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/activities.vue").then(m => m.default || m)
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-favourites",
    path: "/medlem/favoritter",
    meta: favouritescD1IXJhlGEMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/favourites.vue").then(m => m.default || m)
  },
  {
    name: "auth-my-bookings",
    path: "/medlem/mine-bestillinger",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/auth/my-bookings.vue").then(m => m.default || m)
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/blackweek.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "book-tripid-step",
    path: "/bestill/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/book/[tripid]/[step].vue").then(m => m.default || m)
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/booking-confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/booking-finish/[ocr].vue").then(m => m.default || m)
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/bookingfaq/[ocr].vue").then(m => m.default || m)
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/byebyeie.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: index78sOg8UXbfMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/restplasser",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/calendar/lastminute.vue").then(m => m.default || m)
  },
  {
    name: "campaign-name",
    path: "/kampanje/:name()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/campaign/[name].vue").then(m => m.default || m)
  },
  {
    name: "campaign",
    path: "/kampanje",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: cataloguePLi0d49XHvMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/catalogue.vue").then(m => m.default || m)
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_93hDmLT1UHdMMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "charterlastminute",
    path: "/charterlastminute",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/charterlastminute.vue").then(m => m.default || m)
  },
  {
    name: "charterpackagescampaign-name",
    path: "/charterpackagescampaign/:name()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/charterpackagescampaign/[name].vue").then(m => m.default || m)
  },
  {
    name: "charterpackagescampaign-gruppresor",
    path: "/charterpackagescampaign/gruppresor",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/charterpackagescampaign/gruppresor.vue").then(m => m.default || m)
  },
  {
    name: "chartersearch",
    path: "/chartersearch",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/chartersearch.vue").then(m => m.default || m)
  },
  {
    name: "competition-slug",
    path: "/konkurranse/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/competition/[slug].vue").then(m => m.default || m)
  },
  {
    name: "complaint",
    path: "/reklamasjon",
    meta: complaintjxMF9XEJ9cMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/complaint.vue").then(m => m.default || m)
  },
  {
    name: "destination",
    path: "/destination",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/destination/index.vue").then(m => m.default || m)
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/dinbokning-aktuell.vue").then(m => m.default || m)
  },
  {
    name: "event-slug",
    path: "/arrangement/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/event/[slug].vue").then(m => m.default || m)
  },
  {
    name: "event",
    path: "/arrangement",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "favourites",
    path: "/favourites",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/favourites/index.vue").then(m => m.default || m)
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/fn/[id].vue").then(m => m.default || m)
  },
  {
    name: "giftcard-id",
    path: "/gavekort/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/giftcard/[id].vue").then(m => m.default || m)
  },
  {
    name: "giftcard",
    path: "/gavekort",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/giftcard/index.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id-tab",
    path: "/hotell/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotel/[id]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "hotel-backup",
    path: "/hotel/backup",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotel/backup.vue").then(m => m.default || m)
  },
  {
    name: "hotel",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "hotels-name",
    path: "/hoteller/:name()",
    meta: _91name_93sCXxhBlfz7Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotels/[name].vue").then(m => m.default || m)
  },
  {
    name: "hotels",
    path: "/hoteller",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/hotels/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_9374Ci4EcSvZMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/about/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-connections-tab",
    path: "/info/anslutning/:tab()",
    meta: _91tab_93yTonRuKz6oMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/connections/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-grouptravel",
    path: "/info/gruppereiser",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/grouptravel.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info-lectures-tab",
    path: "/info/forelesninger/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/info/lectures/[tab].vue").then(m => m.default || m)
  },
  {
    name: "lastminute",
    path: "/restplasser-tilbud",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/lastminute.vue").then(m => m.default || m)
  },
  {
    name: "map",
    path: "/kart",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/map.vue").then(m => m.default || m)
  },
  {
    name: "mobileApp",
    path: "/mobileApp",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/mobileApp.vue").then(m => m.default || m)
  },
  {
    name: "mytrip-tab",
    path: "/dinreise/:tab()",
    meta: _91tab_931XUZOZkaz8Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/mytrip/[tab].vue").then(m => m.default || m)
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/mytrip/dinbokning.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/offline.vue").then(m => m.default || m)
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93XitAM1NYCDMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/omdome/[id].vue").then(m => m.default || m)
  },
  {
    name: "packagessearch",
    path: "/packagessearch",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/packagessearch.vue").then(m => m.default || m)
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/payment/failed.vue").then(m => m.default || m)
  },
  {
    name: "review",
    path: "/evaluere-reisen",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/review/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "shipinfo-name",
    path: "/cruiseskip/:name()",
    meta: _91name_93Ex3584Wgv4Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/shipinfo/[name].vue").then(m => m.default || m)
  },
  {
    name: "titinerary-slug",
    path: "/dagsprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/titinerary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tourleader-id-slug",
    path: "/reiseleder/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/tourleader/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tourleader",
    path: "/reiseleder",
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/tourleader/index.vue").then(m => m.default || m)
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45donek1vIG4WXF3Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-10-07-1343/rtg-monorepo/pages/verification-done.vue").then(m => m.default || m)
  },
  {
    name: component_45stubzNoTfZ1OzlMeta?.name,
    path: "/dinbokning",
    component: component_45stubzNoTfZ1Ozl
  }
]